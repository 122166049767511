@import "../../styles/Variables.module.scss";

.main {
  display: flex;
  justify-content: center;
  padding: 45px 0 45px 0;

  .container {
    background-color: white;
    border-radius: 15px;
    width: 70vw;

    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      height: 40px;
    }
    h3 {
      font-size: 28px;
      font-weight: 400;
      margin: 0;
      margin-top: 15px;
    }

    .videos {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      flex-wrap: wrap;
      padding: 25px 0 25px 0;

      .video__item {
        width: 30%;

        iframe {
          width: 100%;
          height: 250px;
          border-radius: 15px;
        }

        p {
          font-weight: 500;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .main {
    display: flex;
    justify-content: center;
    padding: 45px 0 45px 0;

    .container {
      width: 90vw;
      padding: 10px;

      img {
        height: 40px;
        padding: 20px 0 0 20px;
      }

      .videos {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        flex-wrap: wrap;
        padding: 25px 0 25px 0;

        .video__item {
          width: 90vw;
        }
      }
    }
  }
}
